import { useState } from 'react';
import Container from './Container';
import SectionTitle from '../SectionTitle';
import styled from '@emotion/styled';
import Image from '../Image';
import { breakpointQueries, breakpoints, spacing } from '../../../utils/styleguide';
import AccordionLayout, { AccordionItem } from './accordionLayout/AccordionLayout';
import { useMediaQuery } from '../../../utils/hooks/useMediaQuery';
import { css } from '@emotion/react';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing[5]}px;
  gap: ${spacing[1]}px;

  ${breakpoints.desktop} {
    flex-direction: row;
  }
`;

const Section = styled.div`
  position: relative;
  flex: 1;
`;

const GridWrapper = styled.div`
  position: relative;
  height: 350px;

  ${breakpoints.desktop} {
    height: 500px;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: ${spacing[1]}px ${spacing[1]}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.images-1 {
    grid-template-columns: 1fr;
    // Extra 8 pixels to account for the row gap
    grid-template-rows: 1fr;
  }

  &.images-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  &.images-3 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3fr 2fr;
  }

  &.images-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const GridItem = styled.div`
  position: relative;

  transition: all 500ms ease-in-out;

  &.hidden {
    transition-property: transform;
    opacity: 0;
    transform: translateY(40px);
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .images-1 {
    &:first-of-type {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }

  &.images-2 {
    &:first-of-type {
      &.visible {
        transition-delay: 300ms;
      }

      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &:nth-of-type(2) {
      &.visible {
        transition-delay: 450ms;
      }

      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  &.images-3 {
    &:first-of-type {
      &.visible {
        transition-delay: 300ms;
      }
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &:nth-of-type(2) {
      &.visible {
        transition-delay: 450ms;
      }
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &:nth-of-type(3) {
      &.visible {
        transition-delay: 600ms;
      }
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  &.images-4 {
    &:first-of-type {
      &.visible {
        transition-delay: 300ms;
      }
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &:nth-of-type(2) {
      &.visible {
        transition-delay: 450ms;
      }
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &:nth-of-type(3) {
      &.visible {
        transition-delay: 600ms;
      }
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &:nth-of-type(4) {
      &.visible {
        transition-delay: 750ms;
      }
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
`;

const imageStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

function ImageStack({ items, visibleLayer = 0 }: { items: Props['items']; visibleLayer: number }) {
  return (
    <GridWrapper>
      {items?.map(({ images }, imageLayer) => {
        if (!images) {
          return null;
        }

        return (
          <Grid key={`image-grid-${images.length}`} className={`images-${images.length}`}>
            {images.map(({ url, description, title }, index) => {
              if (!url || !(description || title)) {
                return null;
              }

              return (
                <GridItem
                  key={`accordion-images-${images.length}-${index}`}
                  className={`images-${images.length} ${
                    visibleLayer === imageLayer ? 'visible' : 'hidden'
                  }`}
                >
                  <Image
                    lazyLoad
                    css={imageStyles}
                    url={url.includes('http') ? url : `https:${url}`}
                    alt={description ?? title ?? ''}
                  />
                </GridItem>
              );
            })}
          </Grid>
        );
      })}
    </GridWrapper>
  );
}

type ImageProps = {
  url?: string;
  title?: string;
  description?: string;
};

type Props = {
  sectionTitle?: string;
  items?: (AccordionItem & { images?: ImageProps[] })[];
};

export default function HighlightAccordionLayout({ sectionTitle, items }: Props) {
  const [expandedIndex, setExpandedIndex] = useState<number>(0);
  const [isDesktopOrLarger] = useMediaQuery(breakpointQueries.desktop);

  function handleOnChange(expandedIndexes: number[]) {
    const [index] = expandedIndexes;
    setExpandedIndex(index);
  }

  const contentWithImages = items?.map((item) => ({
    ...item,
    expandedContent: (
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {item.expandedContent}
        <ImageStack visibleLayer={0} items={[item]} />
      </div>
    ),
  }));

  if (!sectionTitle || !items?.length) return null;

  return (
    <Container>
      <SectionTitle title={sectionTitle} />

      <ContentWrapper>
        {isDesktopOrLarger ? (
          <>
            <Section>
              <AccordionLayout items={items} singleMode onChange={handleOnChange} />
            </Section>
            <Section className="second">
              <ImageStack visibleLayer={expandedIndex} items={items} />
            </Section>
          </>
        ) : (
          <AccordionLayout items={contentWithImages} singleMode onChange={handleOnChange} />
        )}
      </ContentWrapper>
    </Container>
  );
}

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { EntryCollection } from 'contentful';
import {
  TypeLandingPageTemplate,
  TypeBlogPostSkeleton,
  TypeBuildingBlock,
  isTypeBuildingBlock,
  TypeLink,
  TypeLandingPageTemplateSkeleton,
} from '../../../../@types/generated';
import { sendEvent, EVENT_NAMES, sendEventProxy } from '../../../utils/analytics';
import { getEntries } from '../../../utils/api/contentful';
import { spacing, colors } from '../../../utils/styleguide';
import { WithAppProps, getDefaultAppProps } from '../../../utils/withAppProps';
import FundPicker from '../../component-library/FundPicker';
import GhostButton from '../../component-library/buttons/GhostButton';
import MainHero from '../../component-library/heroes/MainHero';
import CTASectionLayout from '../../component-library/layout/CTASectionLayout';
import FormCTA from '../../component-library/layout/FormCTA';
import HighlightAccordionLayout from '../../component-library/layout/HighlightAccordionLayout';
import HighlightLayout from '../../component-library/layout/HighlightLayout';
import PartnerLogosLayout from '../../component-library/layout/PartnerLogosLayout';
import ArticleCarouselLayout from '../../component-library/layout/articleCarouselLayout/ArticleCarouselLayout';
import PartnerCasesLayout from '../../component-library/layout/partnerCasesLayout/PartnerCasesLayout';
import { transformMetaHeaderType } from '../MetaHeader';
import RichText from '../RichText/RichText';

export type StartPageProps = Omit<TypeLandingPageTemplate['fields'], 'metaHeader'> & {
  articles?: EntryCollection<TypeBlogPostSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;
};

function StartPage(props: StartPageProps) {
  const {
    query: { utm_source, utm_content, utm_referrer, utm_campaign, utm_medium },
  } = useRouter();

  useEffect(() => {
    sendEvent({
      name: EVENT_NAMES.viewHomePage,
    });
    sendEventProxy({
      name: EVENT_NAMES.viewHomePageProxy,
      properties: undefined,
    });

    if (utm_campaign) {
      sendEvent({
        name: EVENT_NAMES.viewCampaign,
        properties: {
          'campaign name': utm_campaign,
          referrer: document.referrer,
          'utm source': utm_source,
          'utm content': utm_content,
          'utm referrer': utm_referrer,
          'utm campaign': utm_campaign,
          'utm medium': utm_medium,
        },
      });
    }
  }, [utm_source, utm_content, utm_referrer, utm_campaign, utm_medium]);

  const {
    ctaSectionBackgroundImage,
    ctaSectionButtons,
    ctaSectionText,
    ctaSectionTitle,
    heroCTAs,
    heroImage,
    heroParagraph,
    heroSectionTitle,
    highlightAccordionItems,
    highlightAccordionSectionTitle,
    partnerLogos,
    partnerLogosTitle,
    platformButtons,
    platformImage,
    platformSectionTitle,
    platformText,
    qaButtons,
    qaSectionImage,
    qaSectionTitle,
    qaText,
    fundPickerSectionTitle,
    fundPickerFunds,
    thoughtLeadershipButtons,
    thoughtLeadershipImage,
    thoughtLeadershipSectionTitle,
    thoughtLeadershipText,
    contactUsSectionTitle,
    contactUsTitle,
    contactUsSections,
    partnerCasesSectionTitle,
    partnerCases,
    articles,
  } = props;

  const decoratedHighlightAccordionItems = highlightAccordionItems
    ?.filter((item): item is TypeBuildingBlock => !!item && isTypeBuildingBlock(item))
    .map(({ fields: { description, title, button, images } }) => ({
      title: title ? documentToPlainTextString(title) : undefined,
      expandedContent: (
        <>
          <RichText document={description} withCheckmark />
          {button && button.fields.link && (
            <GhostButton
              href={button.fields.link.fields.externalUrl}
              label={button.fields.label}
              css={{ marginTop: `${spacing[4]}px` }}
              color={colors.purple400}
              hideUnderline
              trailingIconName="chevron-right"
            />
          )}
        </>
      ),
      images: images?.map((image) => ({
        url: image?.fields.file?.url,
        title: image?.fields.title,
        description: image?.fields.description,
      })),
    }));

  return (
    <>
      <MainHero
        sectionTitle={heroSectionTitle}
        paragraph={heroParagraph}
        imageUrl={heroImage?.fields.imageLandscape?.fields.file?.url}
        primaryButtonLabel={heroCTAs?.[0]?.fields.identifier}
        primaryButtonHref={heroCTAs?.[0]?.fields.externalUrl}
        secondaryButtonLabel={heroCTAs?.[1]?.fields.identifier}
        secondaryButtonHref={heroCTAs?.[1]?.fields.externalUrl}
      />

      <PartnerLogosLayout
        title={partnerLogosTitle}
        logosList={partnerLogos?.map((partnerLogo) => ({
          link: partnerLogo?.fields?.link,
          image: {
            url: partnerLogo?.fields?.image?.fields.file?.url ?? '',
            name: partnerLogo?.fields?.image?.fields.file?.fileName,
          },
        }))}
        backgroundColor={colors.grey50}
      />

      <HighlightAccordionLayout
        sectionTitle={highlightAccordionSectionTitle}
        items={decoratedHighlightAccordionItems}
      />

      <FundPicker
        sectionTitle={fundPickerSectionTitle}
        impactFunds={fundPickerFunds?.map((fund) => ({
          title: fund?.fields.title,
          description: fund?.fields.description,
          imageUrl: fund?.fields.image?.fields.file?.url,
          awardsAndStandards: fund?.fields.awardsAndStandards,
          slug: fund?.fields.slug,
        }))}
        backgroundColor={colors.grey50}
      />

      <HighlightLayout
        sectionTitle={thoughtLeadershipSectionTitle}
        text={thoughtLeadershipText}
        buttons={thoughtLeadershipButtons?.filter((button): button is TypeLink => !!button)}
        buttonVariant="text"
        images={[
          {
            imageUrl:
              thoughtLeadershipImage?.fields.imageLandscape?.fields.file?.url ??
              thoughtLeadershipImage?.fields.imagePortrait?.fields.file?.url,
            imageText: thoughtLeadershipImage?.fields.credit,
          },
        ]}
      />

      <CTASectionLayout
        sectionTitle={ctaSectionTitle}
        title={ctaSectionText}
        buttons={ctaSectionButtons?.filter((button): button is TypeLink => !!button)}
        backgroundImageUrl={ctaSectionBackgroundImage?.fields.file?.url}
      />

      <PartnerCasesLayout
        sectionTitle={partnerCasesSectionTitle}
        partnerCases={partnerCases?.map((partnerCase) => ({
          logo: partnerCase?.fields.mediaLogo?.fields.imageLandscape?.fields.file?.url,
          image:
            partnerCase?.fields.mediaBackground?.fields.orientedMedia?.fields.imageLandscape?.fields
              .file?.url,
          text: partnerCase?.fields.body,
          link: {
            title: partnerCase?.fields.link?.fields.identifier,
            url: partnerCase?.fields.link?.fields.externalUrl,
          },
        }))}
        backgroundColor={colors.grey50}
      />

      <HighlightLayout
        sectionTitle={qaSectionTitle}
        text={qaText}
        buttons={qaButtons?.filter((button): button is TypeLink => !!button)}
        buttonVariant="text"
        images={[
          {
            imageUrl:
              qaSectionImage?.fields.imageLandscape?.fields.file?.url ??
              qaSectionImage?.fields.imagePortrait?.fields.file?.url,
            imageText: qaSectionImage?.fields.credit,
          },
        ]}
      />

      <HighlightLayout
        sectionTitle={platformSectionTitle}
        text={platformText}
        buttons={platformButtons?.filter((button): button is TypeLink => !!button)}
        buttonVariant="text"
        images={[
          {
            imageUrl:
              platformImage?.fields.imageLandscape?.fields.file?.url ??
              platformImage?.fields.imagePortrait?.fields.file?.url,
            imageText: platformImage?.fields.credit,
          },
        ]}
        backgroundColor={colors.grey50}
      />

      <ArticleCarouselLayout sectionTitle={'Articles'} articles={articles} />

      <FormCTA
        ctaFormSectionTitle={contactUsSectionTitle}
        ctaFormTitle={contactUsTitle}
        ctaFormSections={contactUsSections?.map((section) => ({
          title: section?.fields.title,
          description: section?.fields.description,
          link: {
            title: section?.fields.link?.[0]?.fields.identifier,
            url: section?.fields.link?.[0]?.fields.externalUrl,
          },
        }))}
        backgroundColor={colors.grey50}
      />
    </>
  );
}

type ServerSideProps = {
  props: StartPageProps & { appProps: WithAppProps['appProps'] };
};

export async function getLandingPageServerSideProps(
  page: TypeLandingPageTemplate,
): Promise<ServerSideProps> {
  const { footerBar, navigationBar } = await getDefaultAppProps();

  const contentfulLatestArticles = await getEntries<TypeBlogPostSkeleton>({
    content_type: 'blogPost',
    'fields.availableFor': 'Main web',
    limit: 9,
    order: ['-fields.publishedAt'],
  });

  return {
    props: {
      identifier: page.fields.identifier,
      heroCTAs: page.fields.heroCTAs,
      heroImage: page.fields.heroImage,
      heroParagraph: page.fields.heroParagraph,
      heroSectionTitle: page.fields.heroSectionTitle,
      partnerLogosTitle: page.fields.partnerLogosTitle,
      partnerLogos: page.fields.partnerLogos,
      highlightAccordionSectionTitle: page.fields.highlightAccordionSectionTitle,
      highlightAccordionItems: page.fields.highlightAccordionItems,
      thoughtLeadershipSectionTitle: page.fields.thoughtLeadershipSectionTitle,
      thoughtLeadershipText: page.fields.thoughtLeadershipText,
      thoughtLeadershipButtons: page.fields.thoughtLeadershipButtons,
      thoughtLeadershipImage: page.fields.thoughtLeadershipImage,
      ctaSectionTitle: page.fields.ctaSectionTitle,
      ctaSectionText: page.fields.ctaSectionText,
      ctaSectionButtons: page.fields.ctaSectionButtons,
      ctaSectionBackgroundImage: page.fields.ctaSectionBackgroundImage,
      qaSectionTitle: page.fields.qaSectionTitle,
      qaText: page.fields.qaText,
      qaButtons: page.fields.qaButtons,
      qaSectionImage: page.fields.qaSectionImage,
      platformSectionTitle: page.fields.platformSectionTitle,
      platformText: page.fields.platformText,
      platformButtons: page.fields.platformButtons,
      platformImage: page.fields.platformImage,
      fundPickerSectionTitle: page.fields.fundPickerSectionTitle,
      fundPickerFunds: page.fields.fundPickerFunds,
      contactUsSectionTitle: page.fields.contactUsSectionTitle,
      contactUsTitle: page.fields.contactUsTitle,
      contactUsSections: page.fields.contactUsSections,
      partnerCasesSectionTitle: page.fields.partnerCasesSectionTitle,
      partnerCases: page.fields.partnerCases,
      articles: contentfulLatestArticles,

      appProps: {
        footerBar: footerBar ? { ...footerBar, disableMaxWidth: true } : undefined,
        navigationBar,
        metaHeader: transformMetaHeaderType({ metaHeader: page.fields.metaHeader }),
      },
    },
  };
}

export async function getServerSideProps(): Promise<ServerSideProps> {
  const { items: landingPages } = await getEntries<TypeLandingPageTemplateSkeleton>({
    content_type: 'landingPageTemplate',
    include: 10,
  });
  const [page] = landingPages;

  if (!page) throw new Error('Page not found');

  return await getLandingPageServerSideProps(page);
}

StartPage.layoutProps = {
  initiallyTransparentHeader: true,
  floatingHeader: true,
};

export default StartPage;

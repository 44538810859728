import styled from '@emotion/styled';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { breakpointQueries, breakpoints, colors, spacing } from '../../utils/styleguide';
import Container from './layout/Container';
import Typography from './text/Typography';
import PrimaryButton from './buttons/PrimaryButton';
import SectionTitle from './SectionTitle';
import Slider from 'react-slick';
// These css imports are necessary for the slider to work and the recommended way from the documentation
// Read more here: https://www.npmjs.com/package/react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { css } from '@emotion/react';
import { useMediaQuery } from '../../utils/hooks/useMediaQuery';
import Image from './Image';
import useComponentInViewport from '../../utils/hooks/useComponentInViewport';
import { TypeImageLinkSkeleton } from '../../../@types/generated';
import { Entry } from 'contentful';
import Logo from './Logo';

const SliderContainer = styled.div<{ numberOfSlides: number }>`
  & .slick-slide {
    box-sizing: border-box;
    padding: 0 ${spacing[0]}px;
    width: ${({ numberOfSlides }) => `calc(50% / ${numberOfSlides - 1})`};
    transition: width 300ms ease-in-out;
  }

  & .slick-slide.slick-current {
    width: 50%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const ImageStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageOverlay = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: ${({ isActive }) =>
    isActive
      ? `linear-gradient(180deg, rgba(0, 0, 0, 0.0) 53.96%, rgba(0, 0, 0, 0.3) 100%)`
      : `linear-gradient(180deg, rgba(0, 0, 0, 0) 53.96%, rgba(0, 0, 0, 0.3) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%)`};
`;

const ContentWrapper = styled.div`
  margin-top: ${spacing[5]}px;

  ${breakpoints.desktop} {
    padding-left: ${spacing[5]}px;
    height: 655px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing[5]}px;
  align-items: flex-start;
`;

const StepperContainer = styled.div`
  display: flex;
  margin: ${spacing[3]}px -${spacing[0]}px ${spacing[3]}px 0;
  gap: ${spacing[1]}px;
`;

const Stepper = styled.div<{ isDesktopOrLarger: boolean }>`
  border-bottom: 2px solid ${colors.grey03};
  transition: all 300ms ease-in-out;
  flex: 1;

  &.active {
    border-color: ${colors.blackPrimary};
    ${({ isDesktopOrLarger }) => (isDesktopOrLarger ? 'flex: 50% 0 1' : 'none')};
  }
`;

const FundCardStyles = css`
  display: flex;
  position: relative;
  height: 300px;
  border-radius: 4px;
  background-color: ${colors.grey03};
  cursor: pointer;
  width: 100%;
`;

const FundCardInnerWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: ${spacing[4]}px;
  overflow: hidden;
  bottom: 0;
  width: -webkit-fill-available;
`;

type CardProps = {
  index: number;
  tabIndex?: number;
  style?: CSSProperties;
  onClick: () => void;
  fund?: {
    title?: string;
    description?: string;
    imageUrl?: string;
  };
  isActive: boolean;
};

function FundCard({ onClick, tabIndex, style, fund, isActive }: CardProps) {
  if (!fund) return null;
  return (
    <div style={style} tabIndex={tabIndex}>
      <div css={FundCardStyles} onClick={onClick}>
        <ImageWrapper>
          {fund.imageUrl && (
            <Image
              lazyLoad
              url={fund.imageUrl}
              css={ImageStyle}
              maxWidth={500}
              alt={`${fund.title} image`}
            />
          )}
        </ImageWrapper>
        {isActive && <ImageOverlay isActive={isActive} />}

        <FundCardInnerWrapper>
          <Typography
            color={colors.white}
            variant="overlineBold"
            css={{
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {fund.title}
          </Typography>
        </FundCardInnerWrapper>
        {!isActive && <ImageOverlay isActive={isActive} />}
      </div>
    </div>
  );
}

export default function FundPicker({
  sectionTitle,
  impactFunds,
  backgroundColor,
}: {
  sectionTitle?: string;
  impactFunds?: {
    title?: string;
    description?: string;
    imageUrl?: string;
    awardsAndStandards?: (
      | Entry<TypeImageLinkSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
      | undefined
    )[];
    slug?: string;
  }[];
  backgroundColor?: string;
}) {
  const sectionRef = useRef<HTMLHeadingElement>(null);
  const isVisible = useComponentInViewport(sectionRef, 0);
  const interval = useRef<NodeJS.Timeout>();
  const sliderRef = useRef<Slider>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const [isDesktopOrLarger] = useMediaQuery(breakpointQueries.desktop);

  const settings = isDesktopOrLarger
    ? {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: impactFunds?.length,
        slidesToScroll: 1,
        variableWidth: true,
      }
    : {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => {
          setActiveIndex(next);
        },
      };

  function handleOnMouseEnter() {
    setIsPaused(true);
    interval?.current && clearInterval(interval?.current);
  }

  function handleOnMouseLeave() {
    setIsPaused(false);
  }

  useEffect(() => {
    // Note - Only auto play on desktop or larger
    if (isPaused || !isDesktopOrLarger || !isVisible) {
      return;
    }

    interval.current = setInterval(
      () =>
        setActiveIndex((prevIndex: number) => {
          const nextIndex = prevIndex + 1;
          const isLastIndex = nextIndex === impactFunds?.length;
          sliderRef.current?.slickGoTo(isLastIndex ? 0 : nextIndex);
          return isLastIndex ? 0 : nextIndex;
        }),
      3000,
    );

    return () => (interval?.current ? clearInterval(interval.current) : undefined);
  }, [impactFunds?.length, interval, isPaused, isDesktopOrLarger, isVisible]);

  if (!impactFunds?.length) return null;

  const totalNrOfLogos = impactFunds[activeIndex].awardsAndStandards?.length || 0;
  const sliceIndex = isDesktopOrLarger ? totalNrOfLogos : 2;

  return (
    <Container backgroundColor={backgroundColor}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      <ContentWrapper onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <SliderContainer ref={sectionRef} numberOfSlides={impactFunds.length}>
          <Slider ref={sliderRef} {...settings} css={{ margin: `0 -${spacing[0]}px` }}>
            {impactFunds.map((fund, index) => {
              return (
                <FundCard
                  key={index}
                  index={index + 1}
                  onClick={() => {
                    setActiveIndex(index);
                    sliderRef.current?.slickGoTo(index);
                  }}
                  fund={fund}
                  isActive={index === activeIndex}
                />
              );
            })}
          </Slider>
        </SliderContainer>

        <StepperContainer>
          {impactFunds.map((_, index) => (
            <Stepper
              key={index}
              className={index === activeIndex ? 'active' : undefined}
              onClick={() => {
                setActiveIndex(index);
                sliderRef.current?.slickGoTo(index);
              }}
              isDesktopOrLarger={isDesktopOrLarger}
            />
          ))}
        </StepperContainer>

        <BodyWrapper>
          <div>
            <Typography variant="h4Bold" style={{ marginTop: `${spacing[3]}px` }}>
              {impactFunds[activeIndex].title}
            </Typography>

            <Typography
              maxNumberOfLines={3}
              variant="body"
              style={{ marginTop: `${spacing[3]}px` }}
            >
              {impactFunds[activeIndex].description}
            </Typography>
          </div>

          {totalNrOfLogos > 0 && (
            <LogoWrapper>
              {impactFunds[activeIndex].awardsAndStandards
                ?.slice(0, sliceIndex)
                .map((award, index) => (
                  <Logo
                    key={index}
                    link={award?.fields.link}
                    logoUrl={award?.fields.image?.fields.file?.url}
                    fileName={award?.fields.image?.fields.file?.fileName}
                    divider={(index + 1) % sliceIndex !== 0}
                  />
                ))}
            </LogoWrapper>
          )}

          <PrimaryButton
            aria-label={`Go to ${impactFunds[activeIndex].title}`}
            size="small"
            style={{ marginTop: `${spacing[3]}px`, alignSelf: 'flex-start' }}
            trailingIconName="chevron-right"
            href={`/impact-funds/${impactFunds[activeIndex].slug}`}
          >
            Read more
          </PrimaryButton>
        </BodyWrapper>
      </ContentWrapper>
    </Container>
  );
}

import Container from '../layout/Container';
import styled from '@emotion/styled';
import Typography from '../text/Typography';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import SectionTitle from '../SectionTitle';
import PrimaryButton from '../buttons/PrimaryButton';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${spacing[5] + spacing[11]}px;
  padding-bottom: ${spacing[9]}px;

  ${breakpoints.desktop} {
    padding-bottom: ${spacing[11]}px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]}px;
  margin-top: ${spacing[6]}px;

  ${breakpoints.tablet} {
    flex-direction: row;
    gap: ${spacing[4]}px;
    padding-left: ${spacing[5]}px;
  }
`;

type Props = {
  primaryButtonLabel?: string;
  primaryButtonHref?: string;
  secondaryButtonLabel?: string;
  secondaryButtonHref?: string;
  imageUrl?: string;
  imageCredit?: string;
  paragraph?: string;
  sectionTitle?: string;
};

export default function MainHero({
  imageUrl,
  sectionTitle,
  paragraph,
  primaryButtonLabel,
  primaryButtonHref,
  secondaryButtonLabel,
  secondaryButtonHref,
}: Props) {
  if (!sectionTitle || !paragraph || !imageUrl) return null;

  return (
    <Container disableVerticalPadding backgroundImage={imageUrl}>
      <ContentWrapper>
        <SectionTitle color={colors.white} title={sectionTitle}>
          <Typography variant={'h1'} color={colors.white} style={{ marginTop: `${spacing[0]}px` }}>
            {paragraph}
          </Typography>
        </SectionTitle>

        {primaryButtonLabel && primaryButtonHref && (
          <ButtonWrapper>
            <PrimaryButton label={primaryButtonLabel} variant="solid" href={primaryButtonHref} />

            {secondaryButtonLabel && secondaryButtonHref && (
              <PrimaryButton
                label={secondaryButtonLabel}
                variant="outline"
                color="white"
                href={secondaryButtonHref}
              />
            )}
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
}

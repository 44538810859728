import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeButtonSkeleton } from './TypeButton';
import type { TypeContextualButtonSkeleton } from './TypeContextualButton';
import type { TypeIllustratedTextSkeleton } from './TypeIllustratedText';
import type { TypeImageLinkSkeleton } from './TypeImageLink';
import type { TypeOrientedMediaSkeleton } from './TypeOrientedMedia';

export interface TypeBuildingBlockFields {
  identifier: EntryFieldTypes.Symbol;
  preheader?: EntryFieldTypes.Symbol;
  title?: EntryFieldTypes.RichText;
  description?: EntryFieldTypes.RichText;
  button?: EntryFieldTypes.EntryLink<TypeButtonSkeleton | TypeContextualButtonSkeleton>;
  bullets?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeIllustratedTextSkeleton>>;
  withCheckmarks?: EntryFieldTypes.Boolean;
  logos?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeImageLinkSkeleton>>;
  media?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  images?: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
}

export type TypeBuildingBlockSkeleton = EntrySkeletonType<TypeBuildingBlockFields, 'buildingBlock'>;
export type TypeBuildingBlock<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeBuildingBlockSkeleton, Modifiers, Locales>;

export function isTypeBuildingBlock<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeBuildingBlock<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'buildingBlock';
}
